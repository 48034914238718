import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

const useYandexMetrica = () => {
  const location = useLocation();

  useEffect(() => {
    if ((window as any).ym) {
      (window as any).ym(98886874, 'hit', location.pathname);
    }
  }, [location.pathname]);
};

export default useYandexMetrica;
